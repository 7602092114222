<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :scroll="{x: true}"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="slot_table_list"
            :is_page="false"
            ref="list"
            rowKey="id"
            :submit_preprocessing="submit_preprocessing"
            @expandedRowsChange="expandedRowsChange($event)"
            @list_after="list_after"
        >
			<template slot="right_btn">
              <!--  <a v-if="this.$store.state.page_auth.export" @click="report()">
                    <a-button class="a_btn">导出</a-button>
                </a> -->
                <!-- <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.export/terminalReport"></export-table> -->
			    <!-- <a-popconfirm v-if="this.$store.state.page_auth.export" title="您确定要导出该报表吗？" ok-text="确认" cancel-text="取消" @confirm="export_excel">
					<a-button class="a_btn">导出</a-button>
				</a-popconfirm> -->
            </template>
            <template v-for="vo in slot_table_list" :slot="vo" slot-scope="data">
                <span v-if="vo == 'customer_num'">{{data.text.num + ' | ' + data.text.ratio + '%'}}</span>
                <span v-else>{{data.text.num + ' | ' + data.text.ratio + '%'}}</span>
            </template>
            <!-- <div v-for="(vo,index) in slot_table_list" :key="index" :slot="vo" slot-scope="data">
                <span v-if="vo == 'customer_num'">{{data.text.num + ' | ' + data.text.ratio + '%'}}</span>
                <span v-else>{{data.text.num + ' | ' + data.text.ratio + '%'}}</span>
            </div> -->
        </TableList>
    </div>
</template>
<script>

import TableList from "@/components/TableList";
import { orderReport, orderReportSub } from "@/api/order";
import { getGoodsTitle,terminalReport,terminalReportSub,terminalReportV2,terminalReportSubV2,getGoodsTitleV2,spreadRatio } from "@/api/goods";

let columns = [
    {
        title: "名称",
		fixed: 'left',
        dataIndex: "name",
    },
    {
        title: "客户数",
		fixed: 'left',
        dataIndex: "customer_num",
        scopedSlots: {
            customRender: "customer_num"
        }
    },
	{
		title: '总数 | 铺市率',
		dataIndex: "total",
		fixed: 'left',
		scopedSlots: {
			customRender: "total"
		}
	}
];

let fixed_columns = [
    {
        title: "名称",
		fixed: 'left',
        dataIndex: "name",
    },
    {
        title: "客户数",
		fixed: 'left',
        dataIndex: "customer_num",
        scopedSlots: {
            customRender: "customer_num"
        }
    },
	{
		title: '总数 | 铺市率',
		dataIndex: "total",
		fixed: 'left',
		scopedSlots: {
			customRender: "total"
		}
	}
];

const columns_details = [
    {
        title: '商品',
        dataIndex: 'goods_title'
    },
    {
        title: '价格',
        dataIndex: 'pay_price'
    },
    {
        title: '数量',
        dataIndex: 'num',
        scopedSlots: {
            customRender: "num"
        }
    },
    {
        title: '小计',
        dataIndex: 'sub_price'
    }
]


export default {
    name: "Index",
    components: {
        TableList,
    },
    data() {
        return {
            get_table_list: terminalReportV2,
            submit_preprocessing: {
                array_to_string: ['department','goods_ids','customer_type','role']
            },
            details: {
                visit_data: {

                },
            },
            slot_table_list: ['total'],
            columns,
			fixed_columns,
            columns_details,
            goods_list: [],
            drawer_visible: false,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
					{
					    type: "date",
					    name: "end_time",
					    title: "日期",
					    options: {
							initialValue:this.$moment(new Date(), 'YYYY/MM/DD')
						},
					},
                    // {
                    //     type: "range_date",
                    //     name: "range_date",
                    //     title: "日期",
                    //     options: {},
                    //     start: {
                    //         name: 'start_time'
                    //     },
                    //     end: {
                    //         name: 'end_time'
                    //     },
                    // },
					{
						type: "select",
						name: "goods_ids",
						title: "品项",
						mode: "multiple",
						options: {},
						list: []
					}, 
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "region_type",
						title: "城乡类型",
						options: {},
						list: [],
					},
                ],

            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});
        this.$method.get_group().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'customer_type', 'treeData', res)
		});
		
		this.$method.get_RegionType().then(res => {
		   this.form_data_seo.list.forEach(item => {
		   	if (item.name == "region_type") {
		   		item.list = res;
		   	}
		   });
		});
		
		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role") {
        //             item.list = res;
        //         }
        //     });
        // });
        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});
		let columns_list = [...this.columns]
		
		this.$method.get_goods_v2().then(res => {
		    this.goods_list = res.data.list;
			this.form_data_seo.list.forEach(item => {
				if (item.name == "goods_ids"){
					item.list = res.data.list;
				}
			});
		    res.data.list.forEach(item => {
				this.slot_table_list.push(item.id)
				columns_list.push(
					{
						title: item.goods_title + ' | 铺市率',
						dataIndex: item.id,
						width: 200,
						scopedSlots: {
							customRender: item.id
						}
					}
				)
		    })
		    this.columns = columns_list;
		})
    },
    mounted() {
        
    },
    methods: {
		report() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"competing_rate_dap",
					...this.$refs.list.seo_data
				}
			});
		},
        export_excel(){
        	spreadRatio({
        		data:{
        			...this.$refs.list.seo_data, // 筛选项表单值
        		},
        		info:true,
        		loading:true
        	})
        },
        get_list_key(list, key, index_arr = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == key) {
                    return list[i]
                }

                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let value = this.get_list_key(list[i].children, key);
                    if (value !== false) {
                        return value;
                    }
                }
            }

            return false;
        },
        expandedRowsChange(e) {
            e.forEach(key => {
                let list = JSON.parse(JSON.stringify(this.$refs.list.list));

                let item = this.get_list_key(list, key);

                if (!item.hasOwnProperty('is_req')) {
                    terminalReportSubV2({
                        data: {
                            id: key,
                            ...this.$refs.list.seo_data
                        }
                    }).then(res => {

                        item.children = [
                            ...res.data.list.map((vo) => {
								if (vo.type == 1){
									vo.children = [];
								}
                                
                                return vo;
                            })

                        ];
                        item.is_req = true;
                        this.$refs.list.set_data('list', list);
                    })
                }
            });
        },
        list_after(res) {
            let list = this.$refs.list.list;
            list = list.map((item,index) => {
				if (item.type == 1){
					item.children = [];
				}
                
                return item;
            });
			let fixed_slot = ['total'];
			let columns_list = [...this.fixed_columns]
			this.$method.get_goods_v2({
				data: {
					...this.$refs.list.seo_data
				}
			}).then(res => {
				this.goods_list = res.data.list;
				res.data.list.forEach(item => {
					fixed_slot.push(item.id)
					columns_list.push(
						{
							title: item.goods_title + ' | 铺市率',
							dataIndex: item.id,
							width: 200,
							scopedSlots: {
								customRender: item.id
							}
						}
					)
				})
				this.slot_table_list = fixed_slot;
				this.columns = columns_list;
			})
        },
        
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.visit_img {
    width: 60px;
    height: 60px;
}
</style>